export enum OfferStatus {
  '',
  'New',
  'Sent to broker',
  'Contract requested',
  'Contract out',
  'Contract in',
  'Contract in Exclusive',
  'Ready for funding',
  'Funded',
  'Expired',
}
