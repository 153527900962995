export enum LenderStatuses {
  Active = 1,
  Setup,
  Reject,
  Accept,
  Review,
}

export enum MgmtFeeBases {
  'Fund Amt' = 1,
  RTR,
}
