import { Injectable, inject } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { RemoteStorageService } from './remote-storage.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class RemoteStorageCachedService extends RemoteStorageService {
  private localStorageService = inject(LocalStorageService);

  set<T = any>(key: string, value: T): Observable<T> {
    this.localStorageService.set(key, value);
    return super.set(key, value);
  }

  get<T = any>(key: string, fallback?: T): Observable<T> {
    const localValue = this.localStorageService.get(key, fallback);
    if (localValue) {
      return of(localValue);
    }
    return super.get<T>(key, fallback).pipe(
      tap(value => {
        if (value) {
          this.localStorageService.set(key, value);
        }
      }),
    );
  }

  delete(key: string): Observable<any> {
    this.localStorageService.delete(key);
    return super.delete(key);
  }
}
