import { ErrorHandler, Injectable, inject } from '@angular/core';
import { environmentToken } from '@mca/shared/util';

import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://ffc80b1c5639466aa68a85312a8caacc@o440036.ingest.sentry.io/5407882',
});

// unused - replaced with built in Sentry Angular service
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private env = inject(environmentToken);

  handleError(error: any): any {
    if (this.env.production) {
      Sentry.captureException(error.originalError || error);
    }
    throw error;
  }
}
