export interface TransRec {
  id: number;
  userid: number;
  mcaid: number;
  mcaref: number;
  externkey?: string;
  transtype?: number;
  transsubtype: number;
  status?: number;
  externstatus?: string;
  ammount: number;
  account: string;
  routing: string;
  acctname?: string;
  accttype: number;
  comment?: string;
  error?: string;
  effectivedate: Date;
  update_timestamp: Date;
  create_timestamp: Date;
  update_user: string;
  create_user: string;
  allocation_tag: string;

  venue: string;
  dealvenue: number;
  processing_venue: number;
  samedayach: boolean;

  assigned?: TransactionAssignment;

  related?: number[];
}

export interface TransactionAssignment {
  ref_id: number | null;
  ref_name: TransAssignmentType | null;
}

export enum TransAssignmentType {
  mca = 'mca',
  user = 'user',
  merchant = 'merchant',
  contractloan = 'contractloan',
  offer = 'offer',
  role = 'role',
  pm = 'pm',
  ext_collector = 'ext_collector',
  int_collector = 'int_collector',
  ext_lawyer = 'ext_lawyer',
  trans_counterpart = 'trans_counterpart',
  settlement_agent = 'settlement_agent',
  ext_debt_settlement_team = 'ext_debt_settlement_team',
}
