export enum BusinessAttributeValueType {
  'number' = 'Number',
  'str' = 'String',
  'date' = 'Date',
  'currency' = 'Currency',
  'bool' = 'Boolean',
}
export enum BusinessAttributeObjectType {
  'dba' = 'DBA',
  'owner' = 'Owner',
}
export enum BusinessAttributeSource {
  'Clear' = 'Clear',
  'Experian' = 'Experian',
}

export interface BusinessAttribute {
  attribute_name: string;
  id: number;
  object_type: keyof typeof BusinessAttributeObjectType;
  source: keyof typeof BusinessAttributeSource;
  value_type: keyof typeof BusinessAttributeValueType;
}

export interface BusinessAttributeUi {
  attribute_name: string;
  id: number;
  object_type: BusinessAttributeObjectType;
  source: BusinessAttributeSource;
  value_type: BusinessAttributeValueType;
}

export interface BusinessAttributeValue {
  id: number;
  value: string | number | boolean | null;
  timestamp: string;
}
