export class BusinessLocationProfileRec {
  businessType = '';
  taxIdNumber = 0;
  haveCashAdvance = false;
  currentCreditCardProcessor = '';
  dateStartedAcceptingCreditCards = new Date();
  useOfProceeds = '';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SICMCC = '';
  grossAnnualSales = 0;
  averageMonthlyCreditCardVol = 0;
  monthVolume1 = 0;
  monthTrans1 = 0;
  monthVolume2 = 0;
  monthTrans2 = 0;
  monthVolume3 = 0;
  monthTrans3 = 0;
  monthVolume4 = 0;
  monthTrans4 = 0;
}
