import { Observable } from 'rxjs';
import { storageKeys } from '../entities/storage';

type StorageKey = keyof typeof storageKeys | string;

export abstract class StorageService {
  abstract set<T = any>(key: StorageKey, value: T): T | Observable<T>;

  abstract get<T = any>(key: StorageKey, fallback?: T): T | Observable<T>;

  abstract delete(key: StorageKey): any | Observable<any>;
}
