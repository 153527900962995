import { getNumber } from '@mca/shared/util';

export class OutstandingLoan {
  name = '';
  date = new Date();
  lasdtPayDate = new Date();

  calcDate = new Date();
  ignorefundingdate = false;
  daysLeft = 0;
  weeksLeft = 0;

  ammount = 0;
  factorRate = 1;
  balanceRemains = 0;
  payment = 0;
  consolidate = false;
  fundingAmmount = 0.0;
  fundingDate = new Date();

  buyout = false;
  refmca = 0;
  reftransaction = 0;

  renewal = false;
  weekly_payment = false;
}

export const initOutstandingLoan = (rec: OutstandingLoan) => {
  rec.ammount = getNumber(rec.ammount, 0);
  rec.factorRate = getNumber(rec.factorRate, 1);
  rec.balanceRemains = getNumber(rec.balanceRemains, 0);
  rec.payment = getNumber(rec.payment, 0);

  if (!rec.date) {
    rec.date = new Date();
  } else {
    rec.date = new Date(rec.date);
  }

  if (!rec.lasdtPayDate) {
    rec.lasdtPayDate = new Date();
  } else {
    rec.lasdtPayDate = new Date(rec.lasdtPayDate);
  }

  if (!rec.calcDate) {
    rec.calcDate = new Date();
  } else {
    rec.calcDate = new Date(rec.calcDate);
  }

  if (!rec.consolidate) {
    rec.consolidate = false;
  }
  if (!rec.ignorefundingdate) {
    rec.ignorefundingdate = false;
  }

  if (!rec.fundingAmmount) {
    rec.fundingAmmount = 0;
  }
  if (!rec.fundingDate) {
    rec.fundingDate = new Date();
  }

  return rec;
};
