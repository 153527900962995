import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, debounceTime, delayWhen, filter, share, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private router = inject(Router);

  readonly loadingEvents$ = new Subject<any>();
  readonly loaded$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    delayWhen(() => this.loadingEvents$.pipe(startWith(), debounceTime(2000))),
    share(),
  );
}
