export class BusinessEventsComponentFilter {
  action: number[] | null = null;
  assigneeType = 0;
  assigneeId: number | null = null;
  subjectType: number[] = [];
  subjectId = 0;
  from: Date | null = new Date();
  to: Date | null = new Date();

  actionSubject = '';
  status?: number[] = [];
  paymentStatus?: number[] = [];
  assignedOnly?: boolean = true;
  latestMcaEvent?: boolean = true;
  roles?: number[] = [];
  subjectAction? = 0;
  id? = 0;
}
