/* eslint-disable @typescript-eslint/naming-convention */
export class BankStatementRec {
  grossDepositsAmt = 0;
  depositsAmt = 0;
  avgDailyBankBalanceAmt = 0;
  monthlyWithdrawalsAmt = 0;
  endMonthBankBalanceAmt = 0;
  monthlyBankDeposits = 0;
  daysNegative = 0;
  NSFItems = 0;
  ODItems = 0;

  public static includeRecord(rec: BankStatementRec): boolean {
    if (isNaN(rec.grossDepositsAmt)) {
      rec.grossDepositsAmt = 0;
    }
    if (isNaN(rec.depositsAmt)) {
      rec.depositsAmt = 0;
    }
    if (isNaN(rec.avgDailyBankBalanceAmt)) {
      rec.avgDailyBankBalanceAmt = 0;
    }
    if (isNaN(rec.monthlyWithdrawalsAmt)) {
      rec.monthlyWithdrawalsAmt = 0;
    }
    if (isNaN(rec.endMonthBankBalanceAmt)) {
      rec.endMonthBankBalanceAmt = 0;
    }
    if (isNaN(rec.monthlyBankDeposits)) {
      rec.monthlyBankDeposits = 0;
    }
    if (isNaN(rec.daysNegative)) {
      rec.daysNegative = 0;
    }
    if (isNaN(rec.NSFItems)) {
      rec.NSFItems = 0;
    }
    if (isNaN(rec.ODItems)) {
      rec.ODItems = 0;
    }
    return (
      // rec.grossDepositsAmt +
      //   rec.depositsAmt +
      //   rec.avgDailyBankBalanceAmt +
      //   rec.monthlyWithdrawalsAmt +
      //   rec.endMonthBankBalanceAmt +
      //   rec.monthlyBankDeposits +
      //   rec.daysNegative +
      //   rec.NSFItems +
      //   rec.ODItems !==
      // 0
      rec.grossDepositsAmt > 0 && rec.depositsAmt > 0
    );
  }
}

export class BankStatementRecDate {
  data: BankStatementRec;

  constructor(public month: string) {
    this.data = new BankStatementRec();
  }
}

export class BankStatementShit {
  recs: BankStatementRecDate[];
  total: BankStatementRec;
  average: BankStatementRec;

  constructor(public name: string, monthList: string[], public year: number) {
    this.recs = [...monthList.map(m => new BankStatementRecDate(m))];
    this.total = new BankStatementRec();
    this.average = new BankStatementRec();
  }

  public static countIncludeRecords(rec: BankStatementShit): number {
    let cnt = 0;
    rec.recs.forEach(i => {
      if (i.data && BankStatementRec.includeRecord(i.data)) {
        cnt++;
      }
    });
    return cnt;
  }

  static copy(statement: BankStatementShit) {
    return {
      recs: statement.recs.map(rec => ({ ...rec })),
      total: { ...statement.total },
      average: { ...statement.average },
    } as BankStatementShit;
  }
}

export const initBankStatementRec = (rec: BankStatementRec) => {
  if (!rec.grossDepositsAmt) {
    rec.grossDepositsAmt = Number(0);
  }
  if (!rec.depositsAmt) {
    rec.depositsAmt = Number(0);
  }
  if (!rec.avgDailyBankBalanceAmt) {
    rec.avgDailyBankBalanceAmt = Number(0);
  }
  if (!rec.monthlyWithdrawalsAmt) {
    rec.monthlyWithdrawalsAmt = Number(0);
  }
  if (!rec.endMonthBankBalanceAmt) {
    rec.endMonthBankBalanceAmt = Number(0);
  }
  if (!rec.monthlyBankDeposits) {
    rec.monthlyBankDeposits = Number(0);
  }
  if (!rec.daysNegative) {
    rec.daysNegative = Number(0);
  }
  if (!rec.NSFItems) {
    rec.NSFItems = Number(0);
  }
  if (!rec.ODItems) {
    rec.ODItems = Number(0);
  }
};
