/* eslint-disable @typescript-eslint/naming-convention */
import { MongoAddress } from './address';
import { BusinessAttributeValue } from './attribute';

export class OwnerPerson {
  firstName = '';
  lastName = '';
  fullName?: string;
  dob?: Date;

  cellPhone = '';
  phone = '';
  extension?: string;
  email = '';

  ssn = '';
  driverLicense = '';

  address = new MongoAddress();
}

export const getOwnerName = (person: OwnerPerson) => {
  if (person.fullName) {
    return person.fullName;
  }
  let name = '';
  if (person.firstName) {
    name += person.firstName;
  }
  if (person.lastName) {
    name += ' ';
    name += person.lastName;
  }

  return name;
};

export enum OwnerReferenceRole {
  onContract = 0,
  bestContact = 1,
  accountReceivablesContact = 2,
  tradeReferenceContact = 3,
}

export const OwnerReferenceRoleOptions = [
  { label: 'On contract', value: OwnerReferenceRole.onContract },
  { label: 'Best contact', value: OwnerReferenceRole.bestContact },
  { label: 'Account Receivables Contact', value: OwnerReferenceRole.accountReceivablesContact },
  { label: 'Trade Reference Contact', value: OwnerReferenceRole.tradeReferenceContact },
];

export class Owner {
  title = '';
  ownershipPCT = 0;
  ownershipStartDate: Date | null = new Date();
  FICOScore = 0;
  bankruptcyAmt = 0;
  judgementsAmt = 0;
  taxLiensAmt = 0;
  creditScore = 0;
  referenceroles: number[] = [OwnerReferenceRole.onContract];

  person = new OwnerPerson();
  attributes?: BusinessAttributeValue[] = [];
}
