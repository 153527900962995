import { Injectable, inject } from '@angular/core';
import { StorageService } from './storage';
import { HttpClient } from '@angular/common/http';
import { environmentToken } from '@mca/shared/util';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable()
export class RemoteStorageService implements StorageService {
  private httpClient = inject(HttpClient);
  private env = inject(environmentToken);
  protected urlPath = 'users/storage/';

  set<T = any>(key: string, value: T): Observable<T> {
    return this.httpClient.put<T>(this.getUrl(key), value);
  }

  get<T = any>(key: string, fallback?: T): Observable<T> {
    return this.httpClient.get<T>(this.getUrl(key)).pipe(
      map(value => (value ?? fallback) as T),
      catchError(() => of(fallback as T)),
    );
  }

  delete(key: string): Observable<any> {
    return this.httpClient.delete(this.getUrl(key));
  }

  protected getUrl(key: string): string {
    return this.env.apiUrl + this.urlPath + key;
  }
}
