import { roundMoney } from '@mca/shared/util';

export enum PaymentFrequency {
  daily = 1,
  weekly = 5,
  biweekly = 10,
  monthly = -1,
}

export const offerFreqOptions = [
  { label: 'Daily', value: PaymentFrequency.daily },
  { label: 'Weekly', value: PaymentFrequency.weekly },
];

export const transactionFreqOptions = [
  ...offerFreqOptions,
  { label: 'Bi-Weekly', value: PaymentFrequency.biweekly },
  { label: 'Monthly', value: PaymentFrequency.monthly },
];

export const paymentFreqLabels = {
  [PaymentFrequency.daily]: 'Daily',
  [PaymentFrequency.weekly]: 'Weekly',
  [PaymentFrequency.biweekly]: 'Bi-Weekly',
  [PaymentFrequency.monthly]: 'Monthly',
};

export enum PaymentTenor {
  daily = 1,
  weekly,
  biweekly,
  monthly,
  quarterly,
  semiannual,
  annual,
  onetime,
  scheduled,
  lastDayOfMonth,
  firstDayOfMonth,
}

export class OCPayment {
  effectivedate?: Date;
  processed?: boolean;

  constructor(
    effectivedate = new Date(),
    public ammount = 0,
    processed = false,
    public fee?: number,
  ) {
    this.effectivedate = effectivedate;
    this.processed = processed;
  }

  static roundMoney(payment: OCPayment) {
    return new OCPayment(payment.effectivedate, roundMoney(payment.ammount), payment.processed, payment.fee && roundMoney(payment.fee));
  }
}
