/* eslint-disable @typescript-eslint/naming-convention */
import { getDateAt } from '@mca/shared/util';

export enum PaymentTypes {
  '',
  'Same Business Day ACH (Must be Submitted by 2:00 PM)',
  'Next Business Day ACH (Must be Submitted by 5:30 PM)',
  'Same Business Day WIRE (Must be Submitted by 2:00 PM)',
}

export const PaymentTypeOptions = [
  { value: 2, label: PaymentTypes[2] },
  { value: 1, label: PaymentTypes[1] },
  { value: 3, label: PaymentTypes[3] },
];

export const PaymentTypeLimits = {
  0: getDateAt({ day: new Date().getDate() + 1, hour: 0, min: 0, sec: 0, ms: 0 }),
  1: getDateAt({ hour: 14, min: 0, sec: 0, ms: 0 }),
  2: getDateAt({ hour: 17, min: 30, sec: 0, ms: 0 }),
  3: getDateAt({ hour: 14, min: 0, sec: 0, ms: 0 }),
};

export enum RescheduleTypes {
  '',
  'Keep the remaining schedule unchanged',
  'Shift the remaining schedule forward',
}

export interface UnderwriterAction {
  note: string;
  newDate?: Date;
  newAmount?: number;
  all?: boolean;
  contractFee?: number;
  external_funding?: boolean;
  use_discount_rate?: boolean;
  stacking_fee?: number;
  default_fee?: number;
  payment_type?: PaymentTypes;
  reschedule_type?: RescheduleTypes;
}
