export class Address {
  id = 0;
  street_line1 = '';
  street_line2 = '';
  city = '';
  state = '';
  zip = '';
  county = '';
  country = 'US';
  ref_id = 0;
  ref_type = 0;
}

export class MongoAddress {
  streetLine1 = '';
  streetLine2 = '';
  city = '';
  state = '';
  zip = '';
  county = '';
  country = 'US';
}

// wrap MongoAddress to behave like Address
export const mongoAddressProxyHandler = {
  get: (target: any, prop: keyof Address) => {
    switch (prop) {
      case 'street_line1':
        return target['streetLine1'];
      case 'street_line2':
        return target['streetLine2'];
      default:
        return target[prop];
    }
  },

  set: (target: any, prop: keyof Address, value: any) => {
    switch (prop) {
      case 'street_line1':
        target['streetLine1'] = value;
        break;
      case 'street_line2':
        target['streetLine2'] = value;
        break;
      default:
        target[prop] = value;
    }
    return true;
  },
};

export const isMongoAddress = (value: MongoAddress | Address): value is MongoAddress => 'streetLine1' in value;
export const isPostgresAddress = (value: MongoAddress | Address): value is Address => 'street_line1' in value;
