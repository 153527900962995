/* eslint-disable @typescript-eslint/naming-convention */
import { McaRateTypes } from './mca';
import { OfferStatus } from './offer-status';
import { UnderwriterAction } from './underwriter';

export interface BusinessEventModel<T> {
  id: number;
  batch_id: number;
  create_ts: Date;
  create_user: number;
  action: BusinessActionTypes;
  assignee_id: number;
  assignee_type: number;
  subject_id: number;
  subject_type: number;
  subject_action?: T;
  note: string;
  trans_id: number;
  status: number;
  payment_status: number;
  display_roles: number[];
  parent_id: number;
}

export interface BusinessEventAction extends UnderwriterAction {
  action?: string;
  from?: number | string;
  to?: number | string;
  breachDate?: string;
  uccFilingStatus?: string;
  fileNumber?: number;
  event?: string;
  offerStatusFrom?: OfferStatus;
  offerStatusTo?: OfferStatus;

  startDate?: Date;
  paymentType?: string;
  paymentMethod?: string;
  initialDate?: string;
  initialAmount?: number;
  transCount?: number;
  recurringAmount?: number;

  numOfPayments?: number;
  frequency?: number;
  amount?: number;
  holdAllPayments?: boolean;
  holdStartDate?: string;
  holdEndDate?: string;
  users?: { comm_extra: number; commission: number; userid: number }[];
  termin_fee?: number;

  useDiscountRate?: boolean;
  reminderType?: number;
  reminderDate?: Date;
  settlement_balance: number;
  rate_type: McaRateTypes;

  accounttype?: number;
  account?: number;
  routing?: number;
  name?: string;
  is_active?: boolean;
  is_default?: boolean;
  is_declined?: boolean;

  effectiveDate?: Date;
  ids: number[];
  request?: string;

  ar_user_fullname?: string;
  ar_user_id?: number;
}

export interface LastIncrementEventAction {
  action: 'last_increment';
  event: string;
}

export type BusinessEventApproval = BusinessEventModel<BusinessEventAction>;
export type BusinessEventLastIncrement = BusinessEventModel<LastIncrementEventAction>;

export enum BusinessActionTypes {
  '',
  'Assign',
  'Complete',
  'Unassign',
  'In Progress',
}

export enum BusinessEventSubjectTypes {
  '',
  'MCA',
  'Transaction',
}

export enum BusinessEventAssigneeTypes {
  '',
  'User',
  'Role',
}
