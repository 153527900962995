import { MongoAddress } from './address';
import { BusinessAttributeValue } from './attribute';

export class DBARec {
  legalName = '';
  dbaName = '';
  phone = '';
  fax = '';
  email = '';
  website = '';
  taxId = '';
  contactName = '';
  businessStartDate?: Date;
  yearsAtLocation = 0;
  locationCount = 0;
  typeOfBusinessEntity = 'LLC';
  stateOfIncorporation = '';
  industryClassification = 0;

  address = new MongoAddress();
  attributes: BusinessAttributeValue[] = [];

  static hasFixedFee(dba: DBARec) {
    return dba.address.state === 'CA';
  }
}
