import { Injectable } from '@angular/core';
import { StorageService } from './storage';

@Injectable()
export class LocalStorageService implements StorageService {
  set<T = any>(key: string, value: T): T {
    window.localStorage[key] = typeof value === 'object' ? JSON.stringify(value) : value;
    return value;
  }

  get<T = any>(key: string, fallback?: T): T {
    const value = window.localStorage[key] ?? fallback;
    try {
      return (JSON.parse(value) as T) ?? (fallback as T);
    } catch {}
    return value;
  }

  delete(key: string): boolean {
    window.localStorage.removeItem(key);
    return true;
  }
}
