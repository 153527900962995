export const storageKeys = {
  mcaTab: 'mca###mca#currentTab',
  mcaListSearch: 'mca##list##search',
  transQuery: 'mca##trans#query',
  investorSelected: 'mca##investor#selected',
  investorCurrentTab: 'mca##investor#selectedTab',
  investorCurrentDashboard: 'mca##investor#curentDashboard',
  investorCurrentProjection: 'mca##investor#curentProjection',
  investorCurrentBenchmark: 'mca##investor#curentBenchmark',
  investorCurrentActive: 'mca##investor#curentActive',
  investorCurrentOffers: 'mca##investor#curentOffers',
  usertypeTransactions: 'mca##reports##usertypeTransactions',
  userDepositTransactions: 'mca##reports##userDepositTransactions',
  issuerSelected: 'loan##issuer#selected',
  loanTransQuery: 'loan##trans#query',
};
