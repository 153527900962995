export enum DocType {
  'mca' = 1,
  'user',
  'merchant',
  'contractloan',
  'offer',
  'role',
  'pm',
}

export const DocTypeOptions = [
  { value: DocType.mca, label: 'MCA' },
  { value: DocType.user, label: 'User' },
  { value: DocType.merchant, label: 'Merchant' },
  { value: DocType.contractloan, label: 'Loan' },
  { value: DocType.pm, label: 'PM' },
];

export interface DocCategoryData {
  id: number;
  category: string;
  description: string;
  roles: number[];
  doc_ref_type_id: DocType;
}

export class DocCategory implements DocCategoryData {
  id = 0;
  category = '';
  description = '';
  roles: number[] = [];
  doc_ref_type_id = DocType.mca;
}
